import { stylesheet } from 'typestyle'

export default stylesheet({
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -100,
  },
  pWanderingmoon: {
    margin: '0 auto',
    width: '60%',
  },
  detailsAndImageDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'left',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 600,
  },
  figure: {
    width: '30%',
  },
  figure_img: {
    width: '100%',
  },
  quantumPlanets_ul: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 0,
    marginTop: '25px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttons__reset: {
    width: '15%',
    border: 'none',
    borderRadius: '6px',
    backgroundColor: '#b75053',
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 800,
    textAlign: 'center',
    height: '35px',
    marginTop: '5px',
    textDecoration: 'none',
    lineHeight: '35px',
    '&:hover': {
      backgroundColor: ' #8a2c2f',
      color: '#ffffff',
      textDecoration: 'none',
    },
  },
  buttons__checkAnswers: {
    width: '15%',
    border: 'none',
    borderRadius: '6px',
    backgroundColor: '#388861',
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 800,
    textAlign: 'center',
    height: '35px',
    marginLeft: ' 35px',
    marginTop: '5px',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#1f5c3e',
      color: '#ffffff',
      textDecoration: 'none',
    },
  },
  buttons__proceed: {
    width: '15%',
    border: 'none',
    borderRadius: '6px',
    backgroundColor: '#388861',
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 800,
    textAlign: 'center',
    height: '35px',
    marginLeft: '35px',
    marginTop: '5px',
    textDecoration: 'none',
    lineHeight: '35px',
    '&:hover': {
      backgroundColor: '#1f5c3e',
      color: '#ffffff',
      textDecoration: 'none',
    },
  },
  buttons__almostCorrectText: {
    color: '#5341ae',
    fontWeight: 800,
    marginRight: 'auto',
  },
  buttons__incorrectText: {
    color: '#b75053',
    fontWeight: 800,
    marginRight: 'auto',
  },
  correctText: {
    color: '#388861',
    fontWeight: 800,
    marginRight: 'auto',
  },
})
